import { useState, useEffect } from 'react';

interface IUsePaginationReturn {
  currentPage: number;
  pages: number[];
  onClickPage: (page: number) => void;
  goNext: () => void;
  goBack: () => void;
  goToLastPage: () => void;
  goToFirstPage: () => void;
  firstRender: boolean;
}

export const usePagination = (totalPages: number): IUsePaginationReturn => {
  const [currentPage, setCurrentPage] = useState(1);
  const [allPages, setAllPages] = useState([]);
  const [pages, setPages] = useState([]);
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setFirstRender(true);
    setCurrentPage(1);

    if (totalPages % 3 === 0) {
      setAllPages(Array.from({ length: totalPages }, (_, i) => i + 1));
    } else {
      setAllPages(
        Array.from({ length: Math.ceil(totalPages) }, (_, i) => i + 1),
      );
    }
  }, [totalPages]);

  useEffect(() => {
    if (firstRender) setFirstRender(false);

    if ([1, 2].includes(currentPage) && allPages.length === 2) {
      setPages([1, 2]);
    } else if (currentPage === 1 && allPages.length === 1) {
      setPages([1]);
    } else if (currentPage === allPages.length) {
      setPages([currentPage - 2, currentPage - 1, currentPage]);
    } else if (currentPage === 1) {
      setPages([1, 2, 3]);
    } else {
      setPages([currentPage - 1, currentPage, currentPage + 1]);
    }
  }, [allPages, currentPage, firstRender]);

  const goNext = () => {
    if (currentPage + 1 <= allPages.length) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const goBack = () => {
    if (currentPage - 1 >= 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const goToLastPage = () => onClickPage(allPages.length);

  const goToFirstPage = () => onClickPage(1);

  const onClickPage = (page) => {
    setCurrentPage(page);
  };

  return {
    firstRender,
    currentPage,
    pages,
    onClickPage,
    goNext,
    goBack,
    goToLastPage,
    goToFirstPage,
  };
};
