import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask, currencyMask } from '~/utils/masks';

export const HeaderReportSales = [
  {
    id: 1,
    value: 'Nome',
    maxChar: 25,
  },
  {
    id: 2,
    value: 'CPF',
    maxChar: 14,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Estabelecimento',
    maxChar: 20,
  },
  {
    id: 4,
    value: 'Data da Venda',
    maxChar: 15,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Parcelas',
    maxChar: 10,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Valor',
    maxChar: 15,
    textAlign: 'center',
  },
];

export const BodyReportSales = (data: any) => {
  // let x = 0;

  const items = [];

  data?.forEach((i) => {
    const item: IListColumn[] = [
      // {
      //   type: 'id',
      //   value: x++,
      //   maxChar: 5,
      // },
      {
        id: 1,
        value: i.nome,
        maxChar: 25,
      },
      {
        id: 2,
        value: i.cpf ? cpfMask(i.cpf).masked : '',
        maxChar: 14,
        textAlign: 'center',
      },
      {
        id: 3,
        value: i.estabelecimento,
        maxChar: 20,
      },
      {
        id: 4,
        value: i.data ? moment(i.data).format('DD/MM/YYYY HH:mm') : '-',
        maxChar: 15,
        textAlign: 'center',
      },
      {
        id: 5,
        value: i.parcelas,
        maxChar: 10,
        textAlign: 'center',
      },
      {
        id: 6,
        value: `R$ ${currencyMask(i.valorCompra)}`,
        maxChar: 15,
        textAlign: 'center',
      },
    ];
    items.push(item);
  });
  return items;
};
