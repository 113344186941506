import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Container from '~/components/container';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import { cpfMask, IDefaultReturn } from '~/utils/masks';

import * as St from './styles';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { IListColCampaing } from '~/views/LimitIncrease/model';
import Loading from '~/components/loading';
import { IListColumn } from '~/models/listCol';
import TableList from '~/components/tableList';

interface IForm {
  name: string;
  cpf: IDefaultReturn;
}

const ConsultCostumer = () => {
  const [form, setForm] = useState<IForm>({} as IForm);
  const [list, setList] = useState([]);

  const history = useHistory();

  const GetUsers = useApi(Endpoints.consult.getUsers, ApiMethod.GET, {}, true);

  const customerHeader = [
    {
      id: 1,
      value: 'Nome completo',
      maxChar: 20,
      textAlign: 'center',
    },
    {
      id: 2,
      value: 'CPF',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 3,
      value: 'Status',
      maxChar: 15,
      textAlign: 'center',
    },
    {
      id: 4,
      value: 'Perfis',
      maxChar: 10,
      textAlign: 'center',
    },
    {
      id: 5,
      value: 'Ação',
      maxChar: 10,
      textAlign: 'center',
    },
  ];

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const goToDetail = (_, info) => {
    console.log(info);
    history.push('/consult/employee/detail', {
      ...info,
    });
  };

  const goBack = () => {
    history.goBack();
  };

  const handleSubmit = async () => {
    let data;
    try {
      data = await GetUsers.callApi({
        nome: form?.name,
        cpf: form?.cpf?.unmasked,
      });

      console.log(data);

      if (data) {
        const newList = [];

        data.data?.forEach((data) => {
          const item: IListColumn[] = [
            {
              border: true,
              title: 'Nome completo',
              value: data.nome,
              maxChar: 20,
            },
            {
              border: true,
              title: 'CPF',
              value: data.cpf ? cpfMask(data.cpf).masked : '',
              maxChar: 15,
              textAlign: 'center',
            },
            {
              border: true,
              title: 'Status',
              value: data.ativo ? 'ATIVO' : 'INATIVO',
              maxChar: 15,
              textAlign: 'center',
            },
            {
              border: true,
              title: 'Perfis',
              value: data.perfis.join(' / '),
              maxChar: 10,
              textAlign: 'center',
            },
            {
              item: data,
              searchAction: goToDetail,
            },
          ];

          newList.push(item);
        });
        setList(newList);
      } else {
        setList([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDisableConsultButton = () =>
    !form.name?.trim().length && form.cpf?.masked?.length !== 14;

  return (
    <>
      <Loading status={GetUsers.loading} />
      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Input
                label="Nome"
                name="name"
                value={form.name}
                onChange={handleChange}
              />
            </ContainerFlex>
            <ContainerFlex padding={16}>
              <Input
                label="CPF"
                name="cpf"
                placeholder="000.000.000-00"
                maxLength={14}
                mask={MaskTypes.CPF}
                value={form.cpf?.masked}
                onChange={handleChange}
              />
            </ContainerFlex>
            <ContainerFlex padding={16}>
              <ContainerFlex row space gap="8">
                <ContainerFlex>
                  <Button
                    fitContent
                    fullWidth
                    onClick={handleSubmit}
                    disabled={handleDisableConsultButton()}
                    loading={GetUsers.loading}
                  >
                    <span>Consultar</span>
                  </Button>
                </ContainerFlex>
              </ContainerFlex>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>

        {!list.length ? (
          <St.BoxCard>
            <ContainerFlex center>
              <span>Usuário não encontrado</span>
            </ContainerFlex>
          </St.BoxCard>
        ) : (
          <St.BoxCard>
            <ContainerFlex row padding={16}>
              {GetUsers.status === 200 && !list.length ? (
                <ContainerFlex center marginTop={30}>
                  <span>Usuário não localizado</span>
                </ContainerFlex>
              ) : null}
              {list.length > 0 && (
                <TableList customerHeader={customerHeader} list={list} />
              )}
            </ContainerFlex>
          </St.BoxCard>
        )}
      </Container>
    </>
  );
};

export default ConsultCostumer;
