import React from 'react';
import ProposalReportProvider from '~/views/Report/Proposal/context/ProposalReportContext';
import { InvoiceProvider } from '~/views/Report/Invoice/context/InvoiceContext';
import { CardProvider } from '~/views/Report/Card/context/CardContext';
import BenefitReportProvider from '~/views/Report/Benefit/context/BenefitReportContext';
import AccessionSaleReportProvider from '~/views/Report/Wallet/context/AccessionSaleReportContext';
import FinancialReportProvider from '~/views/Report/Financial/context/FinancialReportContext';

import { AuthProvider } from './authentication';
import { ProposalProvider } from './proposal';
import LoanReportProvider from '~/views/Report/Loan/context/LoanReportContext';
import { LeadProvider } from './leads';
import SharedServiceProvider from '~/views/Sales/EventsCenter/Services/sharedServiceProvider';
import SharedCardServicesProvider from '~/views/Sales/EventsCenter/Card/Services/sharedCardServiceProvider';
import SharedBenefitServiceProvider from '~/views/Sales/EventsCenter/Benefits/Services/sharedBenefitServiceProvider';
import EventReportProvider from '~/views/Report/Events/context/EventReportContext';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ProposalReportProvider>
      <ProposalProvider>
        <LeadProvider>
          <BenefitReportProvider>
            <AccessionSaleReportProvider>
              <CardProvider>
                <LoanReportProvider>
                  <EventReportProvider>
                    <InvoiceProvider>
                      <FinancialReportProvider>
                        <SharedServiceProvider>
                          <SharedCardServicesProvider>
                            <SharedBenefitServiceProvider>
                              {children}
                            </SharedBenefitServiceProvider>
                          </SharedCardServicesProvider>
                        </SharedServiceProvider>
                      </FinancialReportProvider>
                    </InvoiceProvider>
                  </EventReportProvider>
                </LoanReportProvider>
              </CardProvider>
            </AccessionSaleReportProvider>
          </BenefitReportProvider>
        </LeadProvider>
      </ProposalProvider>
    </ProposalReportProvider>
  </AuthProvider>
);

export default AppProvider;
