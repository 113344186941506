import React, { useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import ContainerFlex from '~/components/containerFlex';
import Button from '~/components/button';
import endpoints from '~/services/endpoints';
import * as St from './styles';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';
import Container from '~/components/container';

const ActiveLimits: React.FC = () => {
  const [error, setError] = useState<string | null>();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  // Request para API chamando o endpoint da criação do relatório
  const RequestFileDownload = useApi<Blob>(
    endpoints.files.getCancelInactivityReport,
    ApiMethod.GET,
  );

  // Método para consultar o retorno do relatório e fazer
  // o download automático do arquivo em XLS
  const onClickDownloadExcel = async () => {
    try {
      const responseReport = await RequestFileDownload.callApi();

      if (responseReport.status >= 200 && responseReport.status < 300) {
        const binary = atob(responseReport.data.base64);
        const buffer = new ArrayBuffer(binary.length);
        const view = new Uint8Array(buffer);
        const contentType = 'text/csv;charset=UTF-8';

        for (let i = 0; i < binary.length; i += 1) {
          view[i] = binary.charCodeAt(i);
        }

        const url = window.URL.createObjectURL(
          new Blob(['\ufeff', view], { type: contentType }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', responseReport.data.filename);
        link.click();
        window.URL.revokeObjectURL(url);
      } else {
        setDialogIsOpen(true);
        setError('Não foi possível baixar o arquivo..');
      }
    } catch (error) {
      setDialogIsOpen(true);
      setError('Não há arquivos.');
    }
  };

  return (
    <>
      <Dialog
        icon={icons.exclamation}
        open={dialogIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => setDialogIsOpen(false)}
        message={error}
      />

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16}>
              <Button
                fitContent
                loading={RequestFileDownload.loading}
                onClick={onClickDownloadExcel}
              >
                <FaDownload />
                <span style={{ marginLeft: '8px' }}>
                  Baixar cancelamento por inatividade
                </span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>

          <ContainerFlex row space>
            {error && (
              <ContainerFlex center marginTop={30}>
                <St.TitleCategory>{error}</St.TitleCategory>
              </ContainerFlex>
            )}
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default ActiveLimits;
